// Alternative Post Layout - Nav

.home-alt {
  color: $white;

  &:hover {
  opacity: .9;
  }
}

ul.nav-alt a {
  color: $white;

  &:hover {
  opacity: .8;
  }
}


// Alternative Post Layout -  Hero

.post-header-alt {
  padding: 20% 0;
  font-weight: normal;
  color: $white;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;

  .meta {
    padding-top: 2em;
  a:hover {
    opacity: .8;
  }
}
}

.featured-img-banner {
  background-size: cover;
  background-position: center;
  padding: 0 0 2%;
  color: $white;
}

.hero-text-alt {
  color: $white;
}

// Img and text classes

  blockquote {
    font-family: neue-haas-unica, sans-serif;
    font-weight: 400;
    font-style: italic;
    max-width: 650px;
    margin: 2em 0;
    padding-left: 2em;
    border-left: 8px solid $black;
  }



// Editorial/Long Form Posts

.editorial {
  max-width: 800px;

  p {
  margin-bottom: 2em;
  }


h3, h4, h5, h6 {
  max-width: 650px;
}

h3 {
  margin-bottom: .3em;
}

h5 {
  margin-bottom: 1em;
}

}

// HR Classes

hr.border {
  border: 0;
  height: 2px;
  background: $black;
  margin-top: 0;
  margin-bottom: 0;
}

hr.border--bottom {
  border: 0;
  height: 2px;
  background: $black;
  margin-bottom: 4em;
  margin-top: 0;
  @media (min-width: 900px) {
    margin-bottom: 6em;
  }
}

.spacing--bottom {
  margin-bottom: 4em;
  @media (min-width: 900px) {
    margin-bottom: 6em;
  }
}

hr.border--top {
  border: 0;
  height: 2px;
  background: $black;
  margin-top: 4em;
  margin-bottom: 0;
  @media (min-width: 900px) {
    margin-top: 6em;
  }
}


hr.postborder {
  border: 0;
  height: 2px;
  background: $black;
  margin: 4em 0;
  @media (min-width: 900px) {
    margin: 6em 0;
  }
}




// One Column Horizontal Layout

.one-column-h {

    margin-top: 4em;
  @media (min-width: 900px) {
    margin-top: 6em;
  }

li {
  display: grid;
  padding: 4em 0;
  border-top: 2px solid $black;
  @media (min-width: 667px) {
    grid-template-columns: 1fr 2fr;
    grid-gap: 4%;
  }
  @media (min-width: 900px) {
    padding: 12em 0;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4%;
    background: linear-gradient($black,$black) center/2px 65% no-repeat;
  }


  .image {
    margin-bottom: 2em;
    width: 60%;
    @media (min-width: 667px) {
      margin: 0 auto 2em;
      width: 100%;
    }
    @media (min-width: 900px) {
      margin: 0 auto;
      width: 85%;
      max-width: 360px;
    }
  }

  .content {
    max-width: 500px;
    @media (min-width: 667px) {
      margin: auto 0;
      padding-left: 10%;
      width: 100%;
    }
    @media (min-width: 900px) {
      width: 90%;
      max-width: 700px;
    }
  }
}

  img {
    width: auto;
  }

  p {
    padding-bottom: 2em;
  }

  p:last-child {
    padding-bottom: 0;
  }

}


// Two Column Horizontal Layout

.two-column-h {

  list-style-type: none;
  display: grid;

  @media (min-width: 1120px) {
    background: linear-gradient($black,$black) center/2px 100% no-repeat;
    grid-template-columns: 1fr 1fr;
  }

li {
  padding: 4em 0;
  border-top: 2px solid $black;
  @media (min-width: 470px) {
  padding: 6em 0;
  }
}

.image {
  margin-bottom: 2em;
  width: 60%;
  @media (min-width: 470px) {
    float: left;
    width: 30%;
    margin-right: 8%;
  }
}

li:first-child {
  padding: 0 0 4em 0;
  border-top: none;
  @media (min-width: 470px) {
    padding: 6em 0;
    border-top: 2px solid $black;
  }
}

li:nth-child(even) {
.image {
  @media (min-width: 1120px) {
    margin-left: 10%;
  }
}
}

.content {
@media (min-width: 470px) {
  margin: 0 auto;
  float: left;
  width: 55%;
}
@media (min-width: 1120px) {
  width: 50%;
}
}

  p {
    font-size: 1.125em;
    line-height: 1.75em;
    padding-bottom: 1.5em;
  }

  p.meta {
    font-size: 1em;
    line-height: 1.5;
    @media (min-width: 900px) {
      font-size: 1.125em;
    }
  }

  p:last-child {
    padding-bottom: 0;
  }



}

// Two Column Vertical Layout

.two-column-v {
  list-style-type: none;
  grid-template-columns: 1fr;
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  display: grid;
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1120px) {
    grid-row-gap: 6em;
  }

  .fluid-width-video-wrapper {
    margin-bottom: 1.25em;
  }

  p {
    max-width: 700px;
    padding-bottom: 1.5em;
  }

  img {
    margin-bottom: .75em;
  @media (min-width: 900px) {
    margin-bottom: 1.25em;
  }
}

}

// Three Column Vertical Layout

.three-column-v {
  list-style-type: none;
  grid-template-columns: 1fr;
  grid-column-gap: 6em;
  grid-row-gap: 4em;
  display: grid;
  padding-bottom: 6em;
  @media (min-width: 667px) {
    grid-row-gap: 6em;
    grid-column-gap: 4em;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1120px) {
    grid-row-gap: 8em;
    grid-column-gap: 6em;
    grid-template-columns: 1fr 1fr 1fr;
  }

  img {
    padding-bottom: 1.25em;
  }

  p {
    padding-bottom: 1.5em;
  }


  .info {
    float: left;
    width: 95%;
    @media (min-width: 667px) {
      width: 90%;
    }
  }
}


// Four Column Layout


.four-column {
  list-style-type: none;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25em;
  grid-row-gap: 3em;
  display: grid;
  padding-bottom: 1.5em;
  @media (min-width: 667px) {
    grid-column-gap: 2em;
    grid-row-gap: 4em;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 6em;
  }
  @media (min-width: 1120px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 3em;
    grid-row-gap: 5em;
  }

  img {
    padding-bottom: .9em;
  }

  h5 {
    max-width: 350px;
  }

}

// Six Column Layout

.six-column {
  list-style-type: none;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25em;
  grid-row-gap: 3em;
  display: grid;
  padding: .25em 0 0 0;
  @media (min-width: 667px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 6em;
  }
  @media (min-width: 1120px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  p {
    margin-bottom: 0;
  }

  img {
    padding-bottom: 1em;
  }

}

.post-list {
    padding-bottom: 4em;
    padding-top: 2em;

    @media (min-width: 750px) {
    padding-bottom: 6em;
    padding-top: 3em;
    }

    h4 {
      font-family: 'Canela Web', serif;
      font-weight:  500;
      font-style:   normal;
      font-stretch: normal;
      max-width: 600px;
    }

    h4 a{
      font-weight:  500;
    }


  }

  // Related Posts


  .related-posts {
    border-top: 2px solid #292929;
    border-bottom: 2px solid #292929;

    p {
      margin-top: 1.5em;
    }

    .layout--sidebar {
      margin: 2em 0 3em 0;
    }

    .post-info {
      margin: auto 0;
    }

      h3 {
        margin-top: .75em;
        font-size: 1.5em;
        line-height: 1.25;
        @media (min-width: 900px) {
          font-size: 2.375em;
          margin-top: 0;
        }
      }

      h3 a {
        text-decoration: none;
      }

  }

// Video Wrapper

.videoWrapper {
  position: relative;
  width: 100%;
  height: 0;
  background-color: $black;
  &44 {
    padding-top: 100%;
  }
  &43 {
    padding-top: 75%;
  }
  &169 {
    padding-top: 56%;
  }
}
.videoIframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
