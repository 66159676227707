// Site Container

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  *zoom: 1;
  overflow: hidden;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

// Header

.site-header{
  overflow: hidden;
  padding-top: 1em;
  border-bottom: 2px solid $black;

  @media (min-width: 750px) {
  padding-top: 2em;
  }

  h1{
     display: inline-block;
   }
}

.site-header-alt{
  overflow: hidden;
  padding-top: 1em;
  background-color: #transparent;
  border-bottom: 2px solid $white;

  @media (min-width: 750px) {
  padding-top: 2em;
  }

  h1{
     display: inline-block;
   }
}

.nav{
  list-style-type: none;
  float: none;
  padding-bottom: 2em;

  @media (min-width: 750px) {
    float: right;
    padding-bottom: 2.5em;
  }

  li{
    display: inline-block;
    margin-top: .5em;
    margin-right: 2em;
    font-size: 1.125em;

    @media (min-width: 750px) {
    margin-right: 5em;
    }

   a {
     text-decoration: none;
   }

  }
  li:last-child {
    margin-right: 0;
  }
}


.site-footer{
  padding: 2em 0 2em 0;
  @media (min-width: 750px) {
    padding: 4em 0 4em 0;
  }
}

// Layouts


.layout--sidebar {
  display: grid;
  margin: 3em auto;
  @media (min-width: 900px) {
    grid-template-columns: 425px 1fr;
    grid-gap: 4%;
    margin: 6em auto;
  }

  p {
    padding-top: .5em;
    max-width: 700px;
  }
}

.layout--split {
  display: grid;
  margin-bottom: 4em;
  border-bottom: 2px solid $black;
  @media (min-width: 900px) {
    grid-template-columns: 50% auto;
    margin-bottom: 6em;
    column-gap: 2em;
  }
  @media (min-width: 1120px) {
    grid-template-columns: 40% auto;
  }

  .content {
    margin: auto 0;
    max-width: 600px;
  }

  header {
    margin: 4em 0;
    @media (min-width: 900px) {
      max-width: 550px;
      padding-right: 2em;
    }
  }

  img {
      margin-bottom: 4em;
    @media (min-width: 900px) {
      object-fit: cover;
      min-height: 30em;
      margin-bottom: 0;
    }
    @media (min-width: 1120px) {
      min-height: 40em;
    }
  }

}
