// Pagination

.pagination {
  padding: 1.5em 0;
}

.page_number {
  padding: 0 2em;
}

// Blog Tags

.toggle-tags {
  display: block;
  padding: 1.5em 0;
  border-bottom: 2px solid $black;
  @media (min-width: 750px) {
  display: none;
}
}

.tags-expo {
  display: none;
  @media (min-width: 750px) {
  display: block;
  padding-bottom: 2em;
  }
}

.tags-list {
  display: grid;
  padding: 1.5em 0;
  margin-bottom: 2em;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 2px solid $black;

  @media (min-width: 470px) {
    grid-template-columns: repeat(3, 1fr);
}

  @media (min-width: 750px) {
  margin-bottom: 0;
  display: block;
}

  li {
    display: inline-block;
    line-height: 2.5;
    padding: 0;
    @media (min-width: 667px) {
      padding-right: 2.5em;
  }

  a {
    text-decoration: none;
  }

  }
}

// Bookshelf Tags

.filterDiv {
  float: left;
  display: none;
}

.show {
  display: block;
}

.tag {
  cursor: pointer;
}

.tag:hover {
  text-decoration: underline;
  color: #777;
}

.tag.active {
  text-decoration: underline;
}

// Bookshelf CSS

.img-container {
  max-width: 175px;
  height: 250px;
  margin: 1.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
@media (min-width: 470px) {
  height: 275px;
}
}

.img-container--vinyl {
  max-width: 200px;
  margin: 1.25em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bookshelf-content {
  max-width: 200px;
}


.bookshelf-title {
  padding-bottom: .15em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  a {
    text-decoration: none;
  }
}

  .img-shadow {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;


  &:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 10px;
    opacity: 1;
    transition: box-shadow 0.3s ease-in-out;
    }

    img {
      padding-bottom: 0em !important;
    }
  }
