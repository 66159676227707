/* Reset
-------------------------------------------------------------- */
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
html, body, div, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure {
	margin: 0;
	padding: 0;
}
header, footer, nav, section, article, hgroup, figure {
	display: block;
}

// Colors

$black: #292929;
$gray: #777;
$grey: #777;
$white: #fff;


// Font Declarations

body {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 100%;
  color: $black;
  line-height: 1.5;

}


// Headings

h1, h2, h3, h4, h5, h6{
  a{
    color: $black;
  }
}

h1{
  font-family: 'Canela Web', serif;
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
  font-size: 1.85em;
  line-height: 1.4em;

  a {
    text-decoration: none;
    }
}

h2{
    font-family: 'Canela Web', serif;
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
    color: $black;
    font-size: 3em;
    line-height: 1.2;
    margin-bottom: .25em;

    @media (min-width: 1120px) {
      font-size: 4em;
      line-height: 1.2;
    }

    a{
      text-decoration: underline;
      font-weight: normal;
    }
    a:hover{
      color: $gray;
    }
  }

h3{
  font-family: 'Canela Web', serif;
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
  color: $black;
  font-size: 2.325em;
  line-height: 1.25;
  margin-bottom: .15em;

  @media (min-width: 667px) {
    font-size: 3.125em;
  }

  a{
    text-decoration: underline;
    font-weight: normal;
  }
  a:hover{
    color: $gray;
  }
}

h4{
  font-family: neue-haas-unica, sans-serif;
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
  font-size: 1.5em;
  line-height: 1.25;
  margin-bottom: .15em;

  @media (min-width: 667px) {
    font-size: 1.75em;
  }

  a{
    font-weight: normal;
    font-weight:  600;
    text-decoration: none;
    border-bottom: none;
  }
}

h5{
  font-family: neue-haas-unica, sans-serif;
  font-style: normal;
  color: $black;
  font-size: 1.5em;
  line-height: 1.2em;
  margin-bottom: .2em;
}

h6{
  font-family: neue-haas-unica, sans-serif;
  font-style: normal;
  color: $black;
  font-size: 1em;
  line-height: 1.3em;
  @media (min-width: 470px) {
    font-size: 1.125em;
  }
}

// Body Copy Styling

p{
  font-size: 1.125em;
  line-height: 1.5;
  @media (min-width: 470px) {
    font-size: 1.375em;
    line-height: 1.75;
  }
}

em {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 400;
  font-style: italic;
}

strong {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.meta {
  font-size: 1em;
  line-height: 1.5;
}

a {
  // text-decoration: underline;
  color: $black;
  text-decoration: underline;
}

a:hover {
  color: $gray;
}

a img:hover {
  opacity: .6;
}

// Images

img{
	max-width: 100%;
	width: auto;
  display: block;
}
