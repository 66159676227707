// @charset "utf-8";

$black: #212121;
$greigh: #ECECE6;



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "subs",
        "post"
;
